import React from "react";
import GlobalLayout from "../layouts/globalLayout";
import ContactUsForm from "../components/ContactUsForm/contactUsForm";

export default function Contact() {
  return (
    <GlobalLayout>
      <ContactUsForm style={{ marginTop: '40px' }} />
    </GlobalLayout>
  );
}
